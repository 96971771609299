import http from "./axios_init";
export default {
  /**
   * 获取所有的报备分部
   * @returns
   */
  get_all() {
    return http.get("admin/v1/estateAttribute");
  },

  /**
   * 下拉列表获取全部分部
   * @returns 
   */
  get_select() {
    return http.get("admin/v1/estateAttribute/select");
  },


};
